// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tips-tsx": () => import("./../../../src/pages/tips.tsx" /* webpackChunkName: "component---src-pages-tips-tsx" */),
  "component---src-templates-package-tsx": () => import("./../../../src/templates/package.tsx" /* webpackChunkName: "component---src-templates-package-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tip-tsx": () => import("./../../../src/templates/tip.tsx" /* webpackChunkName: "component---src-templates-tip-tsx" */)
}

